import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatInterface.css';
import { auth } from './firebase';

function ChatInterface() {
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [responseColor, setResponseColor] = useState('black');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        console.error("User is not authenticated.");
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleChatSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    
    if (!question.trim()) return;

    try {
      if (!user) {
        setError('You are not logged in. Please log in to continue.');
        setResponse('');
        setIsLoading(false);
        return;
      }

      const idToken = await user.getIdToken();
      const res = await axios.post(
        'https://us-central1-webchatter-27fb8.cloudfunctions.net/chatResponse',
        { question },
        {
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      setResponse(res.data.answer);
      setQuestion(''); // Clear the input after successful submission
    } catch (error) {
      console.error('Error fetching chat response:', error);
      setError('Sorry, there was an error processing your request.');
      setResponse('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleColorChange = (e) => {
    setResponseColor(e.target.value);
  };

  return (
    <div className="chat-interface">
      <div className="chat-response" style={{ color: responseColor }}>
        {error && <p className="error">{error}</p>}
        {response ? <p>{response}</p> : <p className="default-text">Start chat and get responses here!</p>}

        {/* Dropdown for selecting response color inside the response area */}
        <div className="color-selection">
          <label htmlFor="colorSelect">Response Color: </label>
          <select id="colorSelect" value={responseColor} onChange={handleColorChange}>
            <option value="black">Black</option>
            <option value="blue">Blue</option>
            <option value="gray">Gray</option>
            <option value="red">Red</option>
          </select>
        </div>
      </div>

      <form onSubmit={handleChatSubmit}>
        <input
          type="text"
          className="chat-input"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Chat with your stored content ..."
          required
        />

        <button
          type="submit"
          className="chat-send-button"
          style={{
            marginTop: '5px',
            marginLeft: '0px',
            marginBottom: '20px',
            borderRadius: '6px',
            width: '140px' 
          }}
          disabled={isLoading}
        >
          {isLoading ? 'Processing...' : 'Chat 💬'}
        </button>
      </form>
    </div>
  );
}

export default ChatInterface;
