import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PopupChatbot.css';
import { auth } from './firebase';

const PopupChatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([
    { 
      sender: 'assistant', 
      text: 'Hello! I am WebChatter assistant. How can I assist you today?',
      style: { textAlign: 'left', alignSelf: 'flex-start' }
    }
  ]);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        console.error("User is not authenticated.");
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleChatSubmit = async (e) => {
    e.preventDefault();

    if (!question.trim()) return;

    const newMessages = [...messages, { sender: 'user', text: question }];
    setMessages(newMessages);
    setQuestion('');
    setIsLoading(true);

    try {
      if (!user) {
        setMessages(prevMessages => [
          ...prevMessages,
          { sender: 'assistant', text: 'You are not logged in. Please log in to continue.' }
        ]);
        setIsLoading(false);
        return;
      }

      const idToken = await user.getIdToken();

      const res = await axios.post(
        'https://us-central1-webchatter-27fb8.cloudfunctions.net/chatResponse',
        { question },
        {
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setMessages(prevMessages => [
        ...prevMessages,
        { sender: 'assistant', text: res.data.answer }
      ]);
    } catch (error) {
      console.error('Error fetching chat response:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { sender: 'assistant', text: 'Sorry, there was an error processing your request.' }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleChatSubmit(e);
    }
  };

  return (
    <div className="popup-chatbot">
      <button className="chat-popup-button" onClick={togglePopup}>
        Chat with your data 💬
      </button>
      {isOpen && (
        <div className="popup-box">
          <div className="box">
            <span className="close-icon" onClick={togglePopup}>x</span>
            <div className="chat-container">
              <div className="chat-history" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {messages.map((msg, index) => (
                  <div 
                    key={index} 
                    className={msg.sender === 'user' ? 'user-message' : 'assistant-message'}>
                    {msg.text}
                  </div>
                ))}
                {isLoading && (
                  <div className="assistant-message">
                    Loading...
                  </div>
                )}
              </div>
              <form onSubmit={handleChatSubmit} className="chat-box">
                <textarea 
                  className="chat-input"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Ask me anything..."
                  required
                  disabled={isLoading}
                />
                <button className="chat-send-button" type="submit" disabled={isLoading}>
                  {isLoading ? 'Sending...' : 'Send'}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupChatbot;
