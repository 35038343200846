// Sidebar.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './Sidebar.css';
import WebChaterLogo from './WebChatterLogo0.png';
import { auth } from './firebase';
import { ChevronDown, ChevronRight } from 'lucide-react';

function Sidebar({ onSelectFolder, folders, setFolders }) {
  const [selectedFolderId, setSelectedFolderId] = useState('home');
  const [openMenuId, setOpenMenuId] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState({});

  const fetchFolders = useCallback(async () => {
    console.log('Fetching folders...');
    try {
      const user = auth.currentUser;
      if (!user) return;
      const idToken = await user.getIdToken();

      const response = await axios.get('/getFolders', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      console.log('Fetched folders:', response.data);
      setFolders(response.data || {}); // Ensure folders is always an object
    } catch (error) {
      console.error('Error fetching folders:', error.response ? error.response.data : error.message);
    }
  }, [setFolders]);

  useEffect(() => {
    fetchFolders();
  }, [fetchFolders]);

  const addFolder = async (parentId = null) => {
    const folderName = prompt('Enter folder name:');
    if (folderName && folderName.trim()) {
      try {
        const user = auth.currentUser;
        if (!user) return;
        const idToken = await user.getIdToken();

        const response = await axios.post('/addFolder',
          { name: folderName.trim(), parentId },
          { headers: { 'Authorization': `Bearer ${idToken}` } }
        );
        console.log('Folder added:', response.data);
        await fetchFolders();
      } catch (error) {
        console.error('Error adding folder:', error.response ? error.response.data : error.message);
        alert(`Failed to add folder. ${error.response ? error.response.data.error : error.message}`);
      }
    }
  };

  const deleteFolder = async (folderId) => {
    if (window.confirm('Are you sure you want to delete this folder?')) {
      try {
        const user = auth.currentUser;
        if (!user) return;
        const idToken = await user.getIdToken();

        await axios.post('/deleteFolder',
          { id: folderId },
          { headers: { 'Authorization': `Bearer ${idToken}` } }
        );
        console.log('Folder deleted:', folderId);
        await fetchFolders();
      } catch (error) {
        console.error('Error deleting folder:', error.response ? error.response.data : error.message);
        alert(`Failed to delete folder. ${error.response ? error.response.data.error : error.message}`);
      }
    }
  };

  const handleFolderRename = async (folderId) => {
    const newName = prompt('Enter new folder name:');
    if (newName && newName.trim()) {
      try {
        const user = auth.currentUser;
        if (!user) return;
        const idToken = await user.getIdToken();

        const response = await axios.post('/renameFolder',
          { id: folderId, newName: newName.trim() },
          { headers: { 'Authorization': `Bearer ${idToken}` } }
        );
        console.log('Folder renamed:', folderId, newName);
        await fetchFolders();
      } catch (error) {
        console.error('Error renaming folder:', error.response ? error.response.data : error.message);
        alert(`Failed to rename folder. ${error.response ? error.response.data.error : error.message}`);
      }
    }
  };

  const handleFolderClick = (folder) => {
    setSelectedFolderId(folder.id);
    onSelectFolder(folder);
  };

  const toggleMenu = (e, folderId) => {
    e.stopPropagation();
    setOpenMenuId(openMenuId === folderId ? null : folderId);
  };

  const handleMenuAction = async (action, folder, depth, e) => {
    e.stopPropagation();
    setOpenMenuId(null);

    switch (action) {
      case 'rename':
        await handleFolderRename(folder.id);
        break;
      case 'delete':
        await deleteFolder(folder.id);
        break;
      case 'addSubfolder':
        if (depth === 0) { // Only allow adding subfolders to top-level folders
          await addFolder(folder.id);
        } else {
          alert('Subfolders can only be added to top-level folders.');
        }
        break;
      default:
        console.log('Unknown action:', action);
        break;
    }
  };

  const toggleFolderExpansion = (folderId) => {
    setExpandedFolders(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const renderFolders = (foldersObj, depth = 0) => {
    if (!foldersObj || typeof foldersObj !== 'object') {
      return null;
    }

    return Object.entries(foldersObj).map(([key, folder]) => (
      <li
        key={folder.id || key}
        className={`folder-item ${selectedFolderId === folder.id ? 'active-folder' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          handleFolderClick(folder);
        }}
        style={{ paddingLeft: `${depth * 20}px`, marginBottom: '5px' }}
      >
        <div className="folder-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <i className="folder-icon">&#128193;</i>
            {folder.name}
          </span>

          <div className="folder-actions" style={{ display: 'flex', alignItems: 'center' }}>
            {folder.subfolders && Object.keys(folder.subfolders).length > 0 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolderExpansion(folder.id);
                }}
                className="expand-button"
              >
                {expandedFolders[folder.id] ?
                  <ChevronDown size={16} color="black" /> :
                  <ChevronRight size={16} color="black" />
                }
              </button>
            )}
            <div className="kebab-menu">
              <button className="kebab-button" onClick={(e) => toggleMenu(e, folder.id)}>⋮</button>
              {openMenuId === folder.id && (
                <div className="kebab-dropdown show">
                  {folder.id !== 'home' && (
                    <>
                      <button className="menu-item" onClick={(e) => handleMenuAction('rename', folder, depth, e)}>Rename</button>
                      <button className="menu-item" onClick={(e) => handleMenuAction('delete', folder, depth, e)}>Delete</button>
                    </>
                  )}
                  {depth === 0 && (
                    <button className="menu-item" onClick={(e) => handleMenuAction('addSubfolder', folder, depth, e)}>Add Subfolder</button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {folder.subfolders && Object.keys(folder.subfolders).length > 0 && expandedFolders[folder.id] && (
          <ul>
            {renderFolders(folder.subfolders, depth + 1)}
          </ul>
        )}
      </li>
    ));
  };

  return (
    <div className="sidebar">
      <div className="logo-container">
        <img src={WebChaterLogo} alt="Logo" className="sidebar-logo" />
      </div>

      <ul className="folder-list">
        <li
          className={`folder-item ${selectedFolderId === 'home' ? 'active-folder' : ''}`}
          onClick={() => handleFolderClick({ name: 'Home', id: 'home' })}
          style={{ paddingLeft: '0', marginBottom: '5px' }}
        >
          <div className="folder-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <i className="folder-icon" style={{ fontStyle: 'normal' }}>&#127968;</i>
              Home
            </span>
          </div>
        </li>

        {renderFolders(folders)}
      </ul>

      <div className="sidebar-footer">
        <div className="folder-actions">
          <button onClick={() => addFolder()} className="small-btn">Add Folder ✚ 📂</button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
