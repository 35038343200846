import React, { useState } from 'react';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import './LoginPage.css';
import WebChaterLogo from './WebChatterLogo2.png';
import { auth } from './firebase'; // Import auth from firebase.js

function LoginPage({ logoSize = 200 }) { // Added logoSize parameter with default value
  const [errorMessage, setErrorMessage] = useState('');

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // User signed in successfully.
        console.log('User signed in:', result.user);
        // No further action needed here; App component will handle auth state change.
      })
      .catch((error) => {
        console.error('Error during sign in:', error.code, error.message);
        setErrorMessage(error.message);
      });
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <img 
          src={WebChaterLogo} 
          alt="WebChater Logo" 
          className="login-logo" 
          style={{ width: `${250}px`, height: `${225.33}px` }} // Added style for logo size
        />
        <h1>Welcome to WebChatter App</h1>
        <p>Please sign in to continue</p>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button onClick={handleGoogleSignIn} className="google-sign-in-button">
          <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google logo" />
          Sign in with Google
        </button>
      </div>
    </div>
  );
}

export default LoginPage;