// App.js
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import Sidebar from './Sidebar';
import ContentManager from './ContentManager';
import ChatInterface from './ChatInterface';
import './styles.css';
import LoginPage from './LoginPage';
import PopupChatbot from './PopupChatbot';
import { auth } from './firebase';
import ErrorBoundary from './ErrorBoundary';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState({ name: 'Home', id: 'home' });
  const [folders, setFolders] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setIsLoggedIn(true);
        setUser(currentUser);
      } else {
        setIsLoggedIn(false);
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log('App component - Folders state:', folders);
  }, [folders]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsLoggedIn(false);
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleDropContent = async (folderId, content) => {
    if (!user) return;

    try {
      const idToken = await user.getIdToken();
      await axios.post('/moveContent', { folderId, contentId: content.id }, {
        headers: {
          'Authorization': 'Bearer ' + idToken
        }
      });

      console.log('Content moved successfully:', content);
    } catch (error) {
      console.error('Error moving content:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Safely extract user information
  const userName = user && user.displayName ? user.displayName.split(' ')[0] : 'User';
  const userPhotoURL = user && user.photoURL ? user.photoURL : null;

  return (
    <ErrorBoundary>
      <div className="app-container">
        {!isLoggedIn ? (
          <LoginPage />
        ) : (
          <div className="app-main">
            <Sidebar
              onDropContent={handleDropContent}
              onSelectFolder={setSelectedFolder}
              selectedFolder={selectedFolder}
              folders={folders}
              setFolders={setFolders}
            />
            <div className="content-section">
              <div className="user-info d-flex align-items-center mb-4">
                {userPhotoURL && (
                  <img
                    src={userPhotoURL}
                    alt="Profile"
                    className="mr-4"
                    width="40"
                    height="40"
                    style={{ borderRadius: '50%' }}
                  />
                )}
                <h5 className="mb-0" style={{ fontSize: '16px' }}>
                  Welcome {userName}!
                </h5>
                <button className="btn btn-outline-danger ml-auto" onClick={handleLogout}>
                  Logout
                </button>
              </div>
              <ChatInterface user={user} />
              <ContentManager
                selectedFolder={selectedFolder}
                folders={folders}
                setFolders={setFolders}
              />
              <PopupChatbot user={user} />
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default App;
